import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

@Module
export default class UserModule extends VuexModule {
    errors = [];

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Action
    [Actions.USER_PROFILE]() {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get("/user/profile")
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.USERS_LIST]() {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get("/user/list")
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.ADD_USER](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post("/user/create", payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.UPDATE_USER](payload) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.put("/user/update", payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.USER_DELETE](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.delete("/user/delete/" + payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }
}
