import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import UserModule from "@/store/modules/UserModule";
import ProductModule from "@/store/modules/ProductModule";
import StockModule from "@/store/modules/StockModule";
import TransactionModule from "@/store/modules/TransactionModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserModule,
    ProductModule,
    StockModule,
    TransactionModule
  },
});

export default store;
