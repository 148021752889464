enum Actions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",

    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
    /*User*/
    ADD_USER = "addUser",
    USERS_LIST = "getUsers",
    USER_PROFILE = "userProfile",
    USER_DELETE = "userDelete",
    LOGOUT = "logout",
    REGISTER = "register",
    UPDATE_USER = "updateUser",
    VERIFY_AUTH = "verifyAuth",
    LOGIN = "login",
    ACTIVATE = "activate",
    RESET = "reset",
    CHECK_HASH = "checkHash",
    FORGOT_PASSWORD = "forgotPassword",
    /*Product*/
    ADD_PRODUCT = "addProduct",
    UPDATE_PRODUCT = "updateProduct",
    PRODUCTS_LIST = "productsList",
    PRODUCT_DELETE = "productDelete",
    /*STOCK*/
    ADD_STOCK = "addStock",
    UPDATE_STOCK = "updateStock",
    STOCKS_LIST = "stocksList",
    STOCK_DELETE = "stockDelete",
    /*Transaction*/
    TRANSACTIONS_LIST = "transactionsList",
    ADD_TRANSACTION = "addTransaction",
    TRANSACTION_DELETE = 'deleteTransaction',
    TRANSACTION_UPDATE = 'updateTransaction',
}

enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_USER = "setUser",
    SET_PASSWORD = "setPassword",
    SET_ERROR = "setError",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
}

export {Actions, Mutations};
