import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

@Module
export default class TransactionModule extends VuexModule {
    errors = [];

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Action
    [Actions.ADD_TRANSACTION](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post("/transaction/create", payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.TRANSACTIONS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get("/transaction/list", payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.TRANSACTION_DELETE](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.delete("/transaction/delete/" + payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.TRANSACTION_UPDATE](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.put("/transaction/update/", payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }
}
