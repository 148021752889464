import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

@Module
export default class ProductModule extends VuexModule {
    errors = [];

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Action
    [Actions.PRODUCTS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.get("/product/list", payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.ADD_PRODUCT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.post("/product/create", payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.UPDATE_PRODUCT](payload) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.put("/product/update", payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }

    @Action
    [Actions.PRODUCT_DELETE](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader()
            ApiService.delete("/product/delete/" + payload)
                .then(({data}) => resolve(data))
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    reject();
                });
        });
    }
}
